//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createHelpers } from 'vuex-map-fields';
import TopicsList from '../components/TopicsList.vue';
import BaseButton from '../components/BaseButton.vue';

const { mapFields } = createHelpers({
  getterType: 'topics/getField',
  mutationType: 'topics/updateField',
});

export default {
  name: 'TopicsPage',
  components: {
    TopicsList,
    BaseButton,
  },
  data() {
    return {
      drawer: false,
      newEvent: "",
      filterTypeSelected: "mostRecent",
      options: [
        { label: 'Mais ativos', value: 'mostActive', color: 'black' },
        { label: 'Mais comentados', value: 'mostAnswed', color: 'black' },
        { label: 'Novos', value: 'mostRecent', color: 'black' },
      ],
    };
  },
  computed: {
    ...mapFields(['search', 'currentFilter']),
    allTopics() {
      const eventsToShow = this.$store.getters.eventsFiltered;
      return eventsToShow;
    },
  },
  methods: {
    signUp() {
      this.$router.push({ name: "SignUp" });
    },
    filterThis(filterType) {
      this.filterTypeSelected = filterType;
    },
  },
};

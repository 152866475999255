//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";

export default {
  name: "TopicItem",
  props: {
    topic: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      formatDate: "formatDate",
    })
  },
  methods: {
    ...mapActions("topics", [
      "localLoadCurrentTopic",
      "localLoadCurrentTopicReplyes",
    ]),
    async emitThisTopic() {
      //this.$store.commit("topics/SET_CURRENT_TOPIC", this.topic);
      this.$router.push({
        name: "TopicPage",
        params: { topicId: this.topic.id },
      });
    },
    supportsPercentage(type) {
      const posAmount = parseInt(this.topic.positiveSupports, 10);
      const negAmount = parseInt(this.topic.negativeSupports, 10);
      const totalSupports = parseInt(posAmount + negAmount, 10);
      if (type === true) {
        return totalSupports
          ? parseInt((posAmount / totalSupports) * 100, 10)
          : 0;
      }
      return totalSupports
        ? parseInt((negAmount / totalSupports) * 100, 10)
        : 0;
    },
    mask(text, limit = 20){
      if (text.length>limit) return text.substring(0, limit)+'...';
      return text;
    },
  },
};
